import React from 'react';
import '../css/Demo.css';

const Demo = () => {
    return (
        <div className="demo-container">
            <h1>Prototypes Demo Page</h1>

            <section className="demo-section">
                <div className="demo-content">
                    <div className="demo-image">
                        <img src="/assets/images/intelligent-document-processing.png" alt="Intelligent Document Processing" />
                    </div>
                    <div className="demo-text">
                        <h2>Intelligent Document Processing</h2>
                        <p>Leverages AutoGen's multi-agent capabilities for document analysis and Fireworks AI for real-time processing.</p>
                        <span className="watch-demo-btn">Watch Demo</span>
                    </div>
                </div>
            </section>

            <section className="demo-section">
                <div className="demo-content">
                    <div className="demo-image">
                        <img src="/assets/images/automated-devops-assistant.png" alt="Automated DevOps Assistant" />
                    </div>
                    <div className="demo-text">
                        <h2>Automated DevOps Assistant</h2>
                        <p>Combines AutoGen's computational models for development workflows, integrated with AWS Glue and Snowflake.</p>
                        <span className="watch-demo-btn">Watch Demo</span>
                    </div>
                </div>
            </section>

            <section className="demo-section">
                <div className="demo-content">
                    <div className="demo-image">
                        <img src="/assets/images/data-analysis-insights.png" alt="Data Analysis & Insights Platform" />
                    </div>
                    <div className="demo-text">
                        <h2>Data Analysis & Insights Platform</h2>
                        <p>Utilizes open-source LLMs through Fireworks AI, with integration between AutoGen and Snowflake.</p>
                        <span className="watch-demo-btn">Watch Demo</span>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Demo;
