import React from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Demo from './components/Demo';
import Header from './components/Header';
import Home from './components/Home';
import Solutions from './components/Solutions';
import './css/App.css';

function MainContent() {
  const location = useLocation();
  const isHome = location.pathname === '/';

  return (
    <main className={`main-content ${isHome ? 'home-page' : ''}`}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </main>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <MainContent />
      </div>
    </Router>
  );
}

export default App;
