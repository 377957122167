import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Header.css'; // Ensure you import your CSS file

function Header() {
    return (
        <header className="header">
            <div className="header-content">
                <div className="logo-container">
                    <Link to="/">
                        <img src="/assets/images/logo.png" alt="DigiTech Edge Logo" className="logo" />
                    </Link>
                </div>

                <nav className="nav">
                    <ul className="nav-links">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/solutions">Solutions</Link></li>
                        <li><Link to="/about">About Us</Link></li>
                        <li><Link to="/demo">Demo</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}


export default Header;
