import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/Home.css';

function Home() {
    const [videoError, setVideoError] = useState(false);
    const [videoSrc, setVideoSrc] = useState('');
    const [showChat, setShowChat] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [isMinimized, setIsMinimized] = useState(false);

    useEffect(() => {
        fetch('/assets/images/home-page-background.mp4')
            .then(response => response.blob())
            .then(blob => {
                const videoUrl = URL.createObjectURL(blob);
                setVideoSrc(videoUrl);
            })
            .catch(error => {
                console.error('Error loading video:', error);
                setVideoError(true);
            });
    }, []);

    const videoRef = useCallback(node => {
        if (node) {
            console.log('Video element:', node);
            node.addEventListener('canplay', () => {
                console.log('Video can play');
                node.play().catch(error => {
                    console.error("Error attempting to play video:", error);
                    setVideoError(true);
                });
            });

            node.addEventListener('error', (e) => {
                console.error('Video error:', e);
                setVideoError(true);
            });
        }
    }, []);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setShowChat(false);
            setIsClosing(false);
            setIsMinimized(false);
        }, 250);
    };

    const handleMinimize = () => {
        setIsMinimized(!isMinimized);
    };

    const handleChatToggle = () => {
        setShowChat(!showChat);
        setIsClosing(false);
    };

    return (
        <div className="home">
            {!videoError && videoSrc && (
                <video
                    ref={videoRef}
                    className="background-video"
                    autoPlay
                    loop
                    muted
                    playsInline
                    src={videoSrc}
                    onError={(e) => {
                        console.error("Video error:", e);
                        setVideoError(true);
                    }}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        zIndex: 0,
                    }}
                />
            )}

            <div className="overlay"></div>
            
            <div className="content-container">
                <h1>Empowering Businesses with Generative AI & Cloud Solutions</h1>
                <p>Revolutionizing industries with innovative AI-driven solutions that enhance efficiency, customer satisfaction, and business agility.</p>
                
                <div className="cta-buttons">
                    <Link to="/solutions" className="cta-btn primary">Explore Our Solutions</Link>
                    <Link to="/contact" className="cta-btn secondary">Get in Touch</Link>
                </div>
            </div>

            {!showChat && (
                <div className="chat-icon" onClick={handleChatToggle}>
                    <span>💬</span>
                </div>
            )}

            {showChat && (
                <div className="chat-wrapper">
                    <div className={`chat-container ${isMinimized ? 'minimized' : ''} ${isClosing ? 'closing' : ''}`}>
                        <div className="chat-header">
                            <h3>DigiTech Edge Assistant</h3>
                            <div className="chat-header-controls">
                                <button 
                                    className="minimize-button"
                                    onClick={handleMinimize}
                                    title={isMinimized ? "Maximize" : "Minimize"}
                                >
                                    {isMinimized ? '□' : '−'}
                                </button>
                                <button 
                                    className="close-button"
                                    onClick={handleClose}
                                    title="Close chat"
                                >
                                    ×
                                </button>
                            </div>
                        </div>
                        <div className="chat-iframe-container">
                            <iframe 
                                src="https://copilotstudio.microsoft.com/environments/Default-8d7045c0-b9d7-40a3-8fb8-04d3292c8a29/bots/cr4ac_digiTechEdgeAssistant/canvas?__version__=2&enableFileAttachment=false"
                                title="DigiTech Edge Assistant"
                                allow="microphone"
                                sandbox="allow-scripts allow-same-origin allow-forms allow-popups"
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Home;
