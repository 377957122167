import emailjs from 'emailjs-com';
import React, { useState } from 'react';
import '../css/Contact.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: ''
    });
    const [submitStatus, setSubmitStatus] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitStatus('submitting');
        
        try {
            console.log('Sending email with data:', formData);
            await emailjs.send(
                'service_o297767',
                'template_wu60s16',
                {
                    from_name: `${formData.firstName} ${formData.lastName}`,
                    from_email: formData.email,
                    message: formData.message,
                },
                'iqez6DEQ_pQX5SIbs'
            );

            setSuccessMessage('Your message has been sent successfully!');
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                message: ''
            });
            setSubmitStatus('success');
        } catch (error) {
            console.error("Error:", error);
            setError('Failed to send the message. Please try again.');
            setSubmitStatus('error');
        }
    };

    return (
        <div className="contact-container">
            <div className="contact-left">
                <h2>Get In Touch</h2>
                <p>We'd love to hear from you. Please fill out this form or use our chatbot for quick assistance.</p>
                <p>Email: services@global-digitech.net</p>
            </div>
            <div className="contact-right">
                <form onSubmit={handleSubmit} className="contact-form">
                    <div className="name-fields">
                        <div className="form-group">
                            <label htmlFor="firstName">First Name</label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastName">Last Name</label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="submit" className="submit-btn" disabled={submitStatus === 'submitting'}>
                        {submitStatus === 'submitting' ? 'Submitting...' : 'Submit'}
                    </button>
                </form>
                {successMessage && <p className="success-message">{successMessage}</p>}
                {error && <p className="error-message">{error}</p>}
            </div>
        </div>
    );
};

export default Contact;
