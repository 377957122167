import React, { useEffect } from "react";
import '../css/Solutions.css';

const Solutions = () => {
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.className += ' fade-in';
                    observer.unobserve(entry.target);
                }
            });
        }, { threshold: 0.2 });

        document.querySelectorAll('.fade-section').forEach(section => {
            observer.observe(section);
        });
    }, []);

    return (
        <div className="solutions-container">
            <section id="solutions">
                <h2 className="solutions-title">Our Solutions and Services</h2>
                <p className="solutions-intro">
                    At DigiTech, we don't just provide cutting-edge AI and cloud solutions — we tailor them to your unique business needs. Our approach combines industry-leading generative AI technology with a deep understanding of business processes, enabling us to deliver solutions that drive efficiency, innovation, and long-term success. Here's how DigiTech stands out from the rest:
                </p>

                <div className="solution-items">
                    {/* AI-Driven Innovation */}
                    <div className="solution-item fade-section" id="ai-driven">
                        <div className="solution-content">
                            <h3>AI-Driven Innovation for Business Optimization</h3>
                            <p>
                                Unlike other AI companies that offer generic solutions, DigiTech leverages generative AI to create tailored business optimization strategies. Our advanced AI technologies, including Natural Language Processing (NLP), Vision-Language Models, and Custom AI Models, allow us to automate workflows, improve customer interactions, and streamline processes in a way that's specific to your industry and goals.
                            </p>
                            <ul>
                                <li><strong>Text Models:</strong> Automate document creation, customer support, and content generation using cutting-edge AI language models.</li>
                                <li><strong>Vision-Language Models:</strong> Seamlessly integrate image recognition and analysis into business operations, from automated quality checks to visual searches.</li>
                                <li><strong>Custom AI Models:</strong> Fine-tune AI models for your exact needs, ensuring that you don't get a one-size-fits-all solution but a system designed to enhance your unique operations.</li>
                            </ul>
                        </div>
                        <img src="/assets/images/ai-driven-solutions.jpg" alt="AI-Driven Solutions" />
                    </div>

                    {/* Cloud Migration and Modernization */}
                    <div className="solution-item fade-section" id="cloud-migration">
                        <div className="solution-content">
                            <h3>Cloud Migration and Modernization: Scalable, Secure, and Future-Proof</h3>
                            <p>
                                We're not just moving you to the cloud — we're transforming the way your business operates by building a scalable, secure, and modern cloud infrastructure. DigiTech partners with industry leaders like AWS, Azure, and Snowflake to deliver end-to-end cloud migration, data management, and modernization services that help you stay ahead of the curve.
                            </p>
                            <ul>
                                <li><strong>Cloud Strategy & Migration:</strong> Seamlessly migrate your infrastructure, applications, and data to the cloud with minimal disruption to your operations.</li>
                                <li><strong>Cloud-Based Data Solutions:</strong> Leverage cloud platforms like AWS Glue and Snowflake to build real-time data pipelines, data lakes, and collaborative environments that integrate effortlessly with your AI-driven processes.</li>
                                <li><strong>Automation and Efficiency:</strong> Using generative AI, we automate cloud management, DevOps, and data workflows to ensure your cloud operations are efficient, secure, and agile.</li>
                            </ul>
                        </div>
                        <img src="/assets/images/cloud-migration.jpg" alt="Cloud Migration and Modernization" />
                    </div>

                    {/* Data Analytics & AI-Powered Insights */}
                    <div className="solution-item fade-section" id="data-analytics">
                        <div className="solution-content">
                            <h3>Data Analytics & AI-Powered Insights: Turning Data into Strategic Assets</h3>
                            <p>
                                DigiTech doesn't just analyze data — we empower you to unlock actionable insights that drive business growth. Our comprehensive data analytics services, powered by AI and predictive modeling, turn raw data into meaningful trends, forecasts, and decision-making tools.
                            </p>
                            <ul>
                                <li><strong>Advanced Data Analytics & Visualization:</strong> From basic reporting to complex predictive analytics, we help you visualize your data in ways that enhance decision-making.</li>
                                <li><strong>AI Integration with Data:</strong> Our team integrates generative AI models into your data infrastructure, allowing for faster, more accurate insights that adapt as your business grows.</li>
                                <li><strong>End-to-End Data Management:</strong> We offer comprehensive data management services, from data warehousing to automated data pipelines, ensuring that you always have reliable, secure, and up-to-date information.</li>
                            </ul>
                        </div>
                        <img src="/assets/images/data-analysis.jpg" alt="Data Analytics & AI-Powered Insights" />
                    </div>

                    {/* Custom Software Development and DevOps Automation */}
                    <div className="solution-item fade-section" id="custom-software">
                        <div className="solution-content">
                            <h3>Custom Software Development and DevOps Automation: Solutions That Evolve With You</h3>
                            <p>
                                What sets DigiTech apart is our ability to custom-build software solutions that fit seamlessly into your existing ecosystem. Whether you need a mobile app, an enterprise platform, or a fully automated DevOps pipeline, we ensure that your technology scales and evolves alongside your business.
                            </p>
                            <ul>
                                <li><strong>Custom Application Development:</strong> Build powerful web and mobile applications that are designed to meet the specific demands of your business.</li>
                                <li><strong>Automated DevOps & Continuous Integration:</strong> We leverage AI to streamline DevOps processes, ensuring faster deployment, automated testing, and reliable delivery.</li>
                            </ul>
                        </div>
                        <img src="/assets/images/custom-software.jpg" alt="Custom Software Development and DevOps Automation" />
                    </div>

                    {/* Data Security and Compliance */}
                    <div className="solution-item fade-section" id="data-security">
                        <div className="solution-content">
                            <h3>Data Security and Compliance: Protecting Your Business in the Digital Age</h3>
                            <p>
                                In an era where data breaches and cyber threats are increasingly common, DigiTech ensures that your business is protected and compliant with global data standards. We don't just implement security — we integrate AI-driven security solutions that actively monitor, predict, and respond to threats in real time.
                            </p>
                            <ul>
                                <li><strong>Security Audits & Compliance:</strong> Our services include comprehensive audits and consultations to ensure compliance with GDPR, HIPAA, and other international standards.</li>
                                <li><strong>Data Encryption & Protection:</strong> Using advanced encryption and security protocols, we ensure your data is protected both in transit and at rest.</li>
                                <li><strong>AI-Powered Threat Detection:</strong> We implement AI systems that continuously monitor and analyze threats, providing real-time alerts and responses to ensure your data and operations remain secure.</li>
                            </ul>
                        </div>
                        <img src="/assets/images/data-security.jpg" alt="Data Security and Compliance" />
                    </div>

                    {/* Why Choose DigiTech */}
                    <div className="solution-item fade-section" id="why-choose">
                        <div className="solution-content">
                            <h3>Why Choose DigiTech?</h3>
                            <p>
                                What truly sets DigiTech apart from other generative AI companies is our ability to combine AI innovation with a deep understanding of business transformation. We don't just provide technology — we offer comprehensive solutions that solve real business challenges:
                            </p>
                            <ul>
                                <li><strong>Tailored Solutions:</strong> We don't believe in one-size-fits-all. Every solution we offer is customized to your specific needs, ensuring it delivers value where it matters most.</li>
                                <li><strong>Expertise Across Industries:</strong> Our team brings industry-specific expertise, whether you're in healthcare, finance, manufacturing, or retail, ensuring that our AI and cloud solutions are fine-tuned to meet the demands of your market.</li>
                                <li><strong>Long-Term Partnership:</strong> We don't just deliver projects — we build long-term partnerships to ensure our solutions evolve alongside your business and continue to deliver value for years to come.</li>
                            </ul>
                        </div>
                        <img src="/assets/images/parterners.jpg" alt="Why Choose DigiTech" />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Solutions;
