import React from 'react';
import '../css/AboutUs.css'; // Import CSS file
import '../css/Expertise.css'; // Import CSS file

const AboutUs = () => {
    return (
        <section
            id="about"
            className="about-us-section"
            style={{ backgroundColor: "white" }} // White background without image
        >
            <div className="about-us-content">
                {/* About Us Text */}
                <div className="about-us-text">
                    <h1 className="title-large">About Us</h1>
                    <h2 className="title-secondary title-left">Who We Are</h2>
                    <p>
                        At <strong>DigiTech Edge</strong>, we are a seasoned team of technology professionals, each with over 20 years of experience in the IT industry. Our team members have worked with some of the largest consulting firms, including <strong>Accenture</strong> and <strong>IBM</strong>, and have led and contributed to large-scale projects for clients such as <strong>TSA</strong>, <strong>Amtrak</strong>, <strong>JP Morgan Chase</strong>, and various <strong>U.S. government agencies</strong>.
                    </p>
                    <p>
                        Specializing in <strong>digital transformation</strong>, <strong>cloud migration and integration</strong>, and <strong>AI implementations</strong>, we leverage our deep industry knowledge and hands-on experience to drive innovation and enhance operational efficiency across industries like <strong>government</strong>, <strong>transportation</strong>, and <strong>finance</strong>. Our tailored solutions ensure that our clients stay ahead in a rapidly evolving technological landscape.
                    </p>
                </div>

                {/* Image */}
                <div className="about-us-image">
                    <img src="/assets/images/sf-engineer.jpg" alt="Our Team" className="who-we-are-image" />
                </div>
            </div>

            {/* New Team Section */}
            <section className="about-header">
                <h1>The Team Behind DigiTech Edge</h1>
                <p>Meet the dedicated professionals passionate about innovation and transforming businesses with leading-edge AI and cloud solutions.</p>
            </section>

            <section className="team-members">
                <div className="team-member">
                    <img src="/assets/images/jay.png" alt="Jay Wang" />
                    <div className="member-info">
                        <h2>Jay Wang</h2>
                        <p>Jay Wang is the co-founder of DigiTech and a visionary technology leader with over 30 years of experience in digital transformation, cloud migration, and AI-driven solutions. At DigiTech, Jay spearheads the development and integration of innovative services that empower businesses to leverage cutting-edge technologies, from AI-powered business optimization to secure cloud migration strategies. His deep expertise in generative AI, data analytics, and large-scale system architecture has helped diverse industries, including government, transportation, and finance, achieve operational efficiency and drive digital transformation. Jay's leadership is instrumental in delivering customized, high-impact solutions that streamline business processes and enhance customer experiences.</p>
                    </div>
                </div>
                <div className="team-member">
                    <img src="/assets/images/sean.png" alt="Zean Zhang" />
                    <div className="member-info">
                        <h2>Zean Zhang</h2>
                        <p>Zean Zhang is the co-founder of DigiTech and a senior technology executive with over 22 years of experience in digital transformation, ERP system integration, and AI-driven solutions. At DigiTech, Zean leads innovative initiatives in AI, machine learning, and cloud-based technologies to deliver agile and scalable solutions for businesses. His extensive expertise spans industries such as manufacturing, energy, and public services, where he has successfully managed large-scale projects, including procurement digital transformation and intelligent SaaS solutions. With a focus on delivering digital strategies that enhance business processes, Zean is dedicated to helping companies optimize operations and drive innovation through cutting-edge technology.</p>
                    </div>
                </div>
            </section>

            {/* Expertise Section */}
            <section className="expertise-section">
                <h2>Our Expertise</h2>
                <div className="expertise-areas">
                    <div className="expertise-item">
                        <img src="/assets/images/generative-ai.jpg" alt="Generative AI" />
                        <h3>Generative AI Implementation</h3>
                        <p>Unlock the power of AI with custom solutions tailored to your business challenges.</p>
                    </div>
                    <div className="expertise-item">
                        <img src="/assets/images/cloud.jpg" alt="Cloud Migration" />
                        <h3>Cloud Migration & Modernization</h3>
                        <p>Seamless transitions to modern cloud architectures, enhancing flexibility and performance.</p>
                    </div>
                    <div className="expertise-item">
                        <img src="/assets/images/data-01.jpg" alt="Data Visualization" />
                        <h3>Data Visualization & Analytics</h3>
                        <p>Transform raw data into actionable insights for smarter decision-making processes.</p>
                    </div>
                    <div className="expertise-item">
                        <img src="/assets/images/digital-transf.jpg" alt="Digital Transformation" />
                        <h3>Digital Transformation</h3>
                        <p>Leverage Generative AI to enhance web development, system integration, and automation for large-scale projects, driving innovation and operational efficiency.</p>
                    </div>
                </div>
            </section>
        </section>
    );
};

export default AboutUs;